<template>
    <div id="productDetails">
        <van-row>
            <van-col span="24">
               <div class="productHeader">
                   <h4>{{details.name}}</h4>
                   <div>
                       <p>
                            <i class="iconfont  icon-chakan"></i><span>{{details.browseCount}}</span>
                            <i class="iconfont icon-xingxing"></i><span>{{details.collectionCount}}</span>
                       </p>
                       <p v-for="(item, index) in details.labels" :key="index">
                           {{item}}
                       </p>
                   </div>
               </div>
            </van-col>
        </van-row>

        <van-row>
            <van-col span="24">
                <div class="banner">
                    <img v-lazy="details.coverImg"/>
                </div>
            </van-col>
        </van-row>
        
        <van-row type="flex" justify="center">
            <van-col span="20" class="details">
               <div :class="position == 1 ? 'btn-group btn-groupR' : 'btn-group btn-groupL'" :style="isVertical == 'vertical' && 'bottom: 30%'">
                    <div class="vr" v-if="details.vrUrl" @click="jumpVr">
                         <i class="iconfont icon-lianjie"></i>
                         <p v-for="(item, index) in details.vrName || '3D效果图'" :key="index">{{item}}</p>
                    </div>
                   <div class="fav" v-if="details.favFlag" @click="cancelCollection">
                        <i class="iconfont icon-shoucang1"></i>
                    </div>
                    <div class="fav" v-else @click="onCollectionModal">
                        <i class="iconfont icon-xin3"></i>
                    </div>
                   
               </div>

                <div class="video_Box" v-if="details.dyOrKsPosition == 1 && details.dyOrKs">
                    <iframe class="tx_iframe" :src="`//v.qq.com/txp/iframe/player.html?vid=${details.dyOrKs}`" allowFullScreen="true"></iframe>
                </div>

                <div class="content">
                    <div v-for="(item, index) in details.details">
                        
                        <p v-html="item.description"></p>
                        <img v-lazy="item.imageUrl" @click="lockImg(index)"/>
                    </div>
                </div>

                <div class="video_Box" v-if="details.dyOrKsPosition == 0 && details.dyOrKs">
                    <iframe class="tx_iframe" :src="`//v.qq.com/txp/iframe/player.html?vid=${details.dyOrKs}`" allowFullScreen="true"></iframe>
                </div>

            </van-col>
        </van-row>

        
        <van-popup v-model="collectionModal" class="addCollection">
            <AddCollection ref="collection" :productId="detailsData.id" :productIndex="0"  @confirmCollection="confirmCollection"/>
        </van-popup>

        <van-popup v-model="flvAddrModal" class="flvAddrModal" @close="videoUrl = ''">
            <video :src="videoUrl" muted autoplay controls style="width:100%"></video>
            <div v-html="details.flvDesc"></div>
        </van-popup>

        <van-popup v-model="lookImgModal" class="lookImgModal">
            <img :src="activeImg"  @click="lookImgModal = false"/>
        </van-popup>

        <rotate-square5 v-if="isLoading" class="loading"></rotate-square5>
    </div>
</template>

<script>
import {getProductDetail, removeFav, footPrint} from '../api/index'
import AddCollection from './addCollection'
import {RotateSquare5} from 'vue-loading-spinner'
import { ImagePreview } from 'vant'
export default {
    data () {
        return {
            token: '',
            isLoading: false,
            detailsData: { //获取详情入参
                token: '',
                id: ''
            },
            details: '',  //产品详情
            collectionModal: false,
            upFootPrintData:{//上传足迹
                token: '',
                footType: 102, //102：浏览产品 103：添加收藏 104：取消收藏 199：退出浏览
                productId: '', //产品id
                productName: '', //产品名称
                viewingTime: 0, //浏览时间
            },
            startTime: '', //开始时间
            position: '',
            flvAddrModal: false,
            videoUrl: '',
            lookImgModal: false,
            activeImg: '',
            lockImgList:[],
            instance: '',
            isVertical: '', //是否为竖屏
        }
    },
    mounted () {
        const {token, id, vertical, position} = this.$route.query;
        vertical && (this.isVertical = vertical);
        position && (this.position = position);
        this.token = token;
        this.detailsData.token = token;
        this.detailsData.id = id;
        this.startTime = parseInt(new Date() / 1000);
        this.productDetail();
        // localStorage.getItem('position') ? this.position = localStorage.getItem('position') : this.position = 2;
        // window.setPosition = this.setPosition
    },
    activated () {
        
    },
    destroyed () {
        this.instance.close();
        this.endBrower();
    },
    components:{AddCollection, RotateSquare5},
    methods: {
        
        //设置position
        setPosition (position) {
            this.position = parseInt(position);
            localStorage.setItem('position',position);
        },

        //获取产品详情
        async productDetail () {
            this.isLoading = true;
            const {detailsData, token} = this;
            let res = await getProductDetail(detailsData);
            this.details = res.data;
            this.details.details.map(res => {
                this.lockImgList.push(res.imageUrl);
            })
            res.status == 0 && (this.isLoading = false);
            const {id, name} = this.details;
            let date =  parseInt(new Date().getTime() / 1000);
            //上传开始浏览足迹
            this.upFootPrintData.token = token;
            this.upFootPrintData.footType = 102;
            this.upFootPrintData.productId = id;
            this.upFootPrintData.productName = name;
            this.upFootPrintData.viewingTime = date;
            let footRes = await footPrint(this.upFootPrintData);
        },

        //确认收藏后操作
        async confirmCollection (data) {
            const {model, productIndex, isFav} = data;
             this.collectionModal = model;
             if (isFav) {
                this.details.favFlag = true;

                //上传确认收藏足迹
                this.upFootPrintData.footType = 103;
                let footRes = await footPrint(this.upFootPrintData);
             }
        },

        //取消收藏
        async cancelCollection () {
            const {detailsData} = this;
            let res = await removeFav(detailsData);
            if (res.status == 0) {
                this.details.favFlag = false;

                //上传取消收藏足迹
                this.upFootPrintData.footType = 104;
                let footRes = await footPrint(this.upFootPrintData);
            }
        },

        //跳转vr
        jumpVr () {
            window.location.href = this.details.vrUrl;
        },

        //退出浏览
        async endBrower () {
            const {startTime} = this;
            let viewingTime = parseInt(new Date() / 1000) - startTime;
            this.upFootPrintData.footType = 199;
            this.upFootPrintData.viewingTime = viewingTime;
            let res = await footPrint(this.upFootPrintData);
        },

        onCollectionModal () {
            this.collectionModal = true;
            setTimeout(() => {
                this.$refs.collection.restTypes();
            })
        },

        lockImg (index) {
            const {lockImgList} = this;
            this.instance = ImagePreview({
                images: lockImgList,
                startPosition: index,
                className: 'lockImg'
            });
        }
    }
}
</script>

<style lang="less" src="@/style/productDetails.less">

</style>